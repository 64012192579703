<template>
  <div>
    <slot />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase'

export default {
  name: 'Assessment',

  computed: {
    ...mapState('user', ['user']),
  },
  watch: {
    user: function () {
      this.checkUser()
    },
  },
  methods: {
    ...mapActions('candidate', ['loginCandidate']),
    checkUser() {
      if (!this.user || this.user.isAnonymous) {
        // Check if inviteCode is present
        if (this.$route.query.inviteCode) {
          this.loginCandidate({
            inviteCode: this.$route.query.inviteCode,
            url: this.$route.params.url,
          })
            .then((res) => {
              const loginUrl = res.getLoginToken()
              const email = res.getEmail()
              if (firebase.auth().isSignInWithEmailLink(loginUrl)) {
                console.log('Logging in with link')
                firebase
                  .auth()
                  .signInWithEmailLink(email, loginUrl)
                  .then(() => {
                    console.log('Succesfully logged in')
                  })
                  .catch((error) => {
                    console.log('Error logging in', error)
                    return this.redirectToLogin()
                  })
              } else {
                return this.redirectToLogin()
              }
            })
            .catch((error) => {
              console.log('Error logging in', error)
              return this.redirectToLogin()
            })
        } else {
          return this.redirectToLogin()
        }
      }
    },
    redirectToLogin() {
      return this.$router.push('/community/login')
    },
  },
  mounted() {
    this.checkUser()
  },
}
</script>
